import React from "react"



//
//import {createBrowserHistory} from 'history';
//import {useRouterHistory, Router, Route} from 'react-router';
import { browserHistory } from 'react-router';
//import GatsbyConfig from '../../gatsby-config'


import 'isomorphic-fetch'; //index file create



// Import layouts here
import Suwak from "../../layouts/Suwak"

import Menu from "../../layouts/menu";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
// Import styles here
import '../../styles/global.css';
// plugins
import { Helmet }   from "react-helmet";
import i18next from '../../i18n/config';
import CSSTopMenu from "./contact.css"


//import salesMailBialy from "../../img/icon/salesmailbialy.png";

        const $ = require("jquery");
var myClassName = 'red';
// import favicon from '../img/favi_icon_16px.png';
//      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />

// const $ = require("jquery")





//Your initialization






export default  class Home extends React.Component {

    constructor(props) {
        super(props);
    }

    sendMail = (e) => {

        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (typeof window !== `undefined`) {


            const sendButton = document.getElementById('send-button');
            $(sendButton).html(i18next.t('Sending...'));
            var isError = false;
            if ($("#name").val().length < 3) {
                isError = true;
                document.getElementById("name").className = 'formError';
                setTimeout(function () {
                    document.getElementById("name").className = 'formErrorOK';
                }, 1000);
            }


            if ($("#Comment").val().length < 11) {
                isError = true;
                document.getElementById("Comment").className = 'formError';
                setTimeout(function () {
                    document.getElementById("Comment").className = 'formErrorOK';
                }, 1000);
            }





            if (
                    $("#email").val().length > 7
                    ||
                    $("#telephone").val().length > 7
                    ) {
            } else {
                isError = true;
                document.getElementById("email").className = 'formError';
                document.getElementById("telephone").className = 'formError';
                setTimeout(function () {
                    document.getElementById("email").className = 'formErrorOK';
                    document.getElementById("telephone").className = 'formErrorOK';
                }, 1000);
            }







            if (isError) {
                $(sendButton).html(i18next.t('SEND'));
                document.getElementById("formErrorInfo").className = 'formErrorInfoSchow';
                setTimeout(function () {
                    document.getElementById("formErrorInfo").className = 'formErrorInfoHiden';
                }, 1000);
            } else {






//                var grecaptcha = grecaptcha;

                console.log("typeof window:" + typeof window);
                if (typeof window !== `undefined`)
                    console.log("typeof grecaptcha:" + typeof grecaptcha);

                if (typeof grecaptcha === 'object') {
//04.10.2022  var grecaptcha
                    if (false) {
//                        var grecaptcha;
                    }
                    console.log("grecaptcha.execute");

                     grecaptcha.execute('6Ld7cKMUAAAAAPfgT3LOft2g8fRfXjY-C9vQiZZ2', {action: 'contact'}).then(function (token) {



                        document.getElementById('form-phas').value = token;
                        var myform = document.getElementById('myform')
                        var formValues = $(myform).serialize();
                        console.log("post");

                        $.post("/api/sendmail.php", formValues, function (data) {




                            if ($.trim(data) == "ok") {

                                $(sendButton).html(i18next.t('Message sent!'));
                                document.getElementById("email").value = '';
                                document.getElementById("telephone").value = '';
                                document.getElementById("company").value = '';
                                document.getElementById("name").value = '';
                                document.getElementById("Comment").value = '';
                            } else {
                                $(sendButton).html(i18next.t('Error - please resend!'));
                            }


                            $.getScript("/contact/captcha.js", function (data, textStatus, jqxhr) {
//  console.log( data ); // Data returned
//  console.log( textStatus ); // Success
//  console.log( jqxhr.status ); // 200
//  console.log( "Load was performed." );
                            });
                        });
                    });

                }


            }







        }











    }

    render()
    {

        i18next.changeLanguage(this.props.pageContext.language, (err, t) => {
        }
        );
        //require("./captcha.js");

        if (typeof window !== `undefined`) {


            const script2 = document.createElement("script");
            script2.src = '/js/jQuery.js';
            document.head.appendChild(script2);
            const script3 = document.createElement("script");
            script3.src = '/contact/google-maps.js';
            document.head.appendChild(script3);
            var script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAtAdYzcWWTwFFjXC93zvUhMqppa9cbuYY&callback=initMap';
            script.defer = true;
            document.head.appendChild(script);
        }

        var Informacje_finansowe = "";
        var Nieprawidlowosci = "";
        var Informacje_podatkowe_2023 = "";
        if (this.props.pageContext.language == "pl") {
            Informacje_finansowe = 'Informacje finansowe';
            Informacje_podatkowe_2023 = 'Informacja o realizowanej strategii podatkowej';
            Nieprawidlowosci = 'Zgłoś nieprawidłowości';
        }

        const timestamp = new Date().getTime();

        return (
                <React.Fragment>


                    <Helmet>
                        <script src="https://www.google.com/recaptcha/api.js?render=6Ld7cKMUAAAAAPfgT3LOft2g8fRfXjY-C9vQiZZ2"></script>
                        <script src="/contact/captcha.js"></script>

                    </Helmet>



                    <div className="App" >
                        <Helmet>
                            <meta charSet="utf-8" />
                            <link rel="canonical" href="https://www.instanta.pl/" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="icon" type="image/png" href={require('../../img/template/favicon.png')} sizes="16x16" />
                            <title>Instanta Coffee Brand</title>
                            <meta name="Description" content="The company specializes in the production of private label coffee" />
                            <meta name="Keywords" content="private label coffee" />
                        </Helmet>
                        <div class="wrapper">
                            <Menu/>





                            <section id="contact" class="firstSection">
                                <h1> {i18next.t('contacth1')}</h1>
                                <div class="flex">
                                    <div class="address">
                                        <h5 class="title">{i18next.t('h5contact')}</h5>

                                        <p>
                                            {i18next.t('pcontactadd')}
                                        </p>
                                        <p class="title">
                                            {i18next.t('pcontactmail')}
                                        </p>

                                        <a  onClick={() => window.location = ('mai' + 'l' + 'to:sal' + 'es@inst' + 'anta.pl')} id="contactMailSales"   href="#"> &nbsp; </a>

                                        <p class="title">
                                            {i18next.t('pcontactphone')}
                                        </p>
                                        <p>
                                            <a href="tel:+48324724100">+48 32 47 24 100</a>
                                        </p>
                                        <p class="title">
                                            Fax:
                                        </p>
                                        <p>
                                            <a href="tel:+48324724024">+48 32 47 24 024</a>
                                        </p>

                                        
                                        

                                        {i18next.language === 'pl' && (
                                        <>
                                        <br/><p class="Informacje_finansowe" ><a href={`/Informacje_finansowe.pdf?v=${timestamp}`}>{Informacje_finansowe}</a></p>
                                        <br/><p class="Informacje_finansowe" ><a href="/pl/UE/">Dofinansowania</a></p>
                                        <br/><p class="Informacje_finansowe" ><a href="/pl/Nieprawidlowosci">{Nieprawidlowosci}</a></p>
                                        </>
                                        )}

                                    </div>






                                    <div class="form">
                                        <form id="myform"  method="post" action="#send" onSubmit={this.sendMail}>
                                            <div class="formflex">
                                                <div>
                                                    <input type="hidden" name="form-phas" id="form-phas" value="Null" />
                                                </div>
                                                <div>
                                                    <label class="title" for="name">{i18next.t('contactname')}</label>
                                                    <input type="text" name="Name" id="name" className={myClassName} />

                                                </div>
                                                <div>
                                                    <label class="title" for="company">{i18next.t('contactcompany')}</label>
                                                    <input type="text" name="Company" id="company" />

                                                </div>
                                                <div>
                                                    <label class="title" for="email">{i18next.t('contactemail')}</label>
                                                    <input type="email" name="E-mail" id="email" />

                                                </div>
                                                <div>
                                                    <label class="title" for="telephone">{i18next.t('contacttel')}</label>
                                                    <input type="tel" name="telephone" id="telephone" />

                                                </div>
                                                <div>
                                                    <label class="title" for="comment">{i18next.t('contactcomment')}</label>
                                                    <textarea name="Comment" cols="5" id="Comment" ></textarea>
                                                </div>
                                            </div>
                                            <div class="send">
                                                <button type="submit" id="send-button"  >{i18next.t('SEND')}</button>
                                                <p>This site is protected by reCAPTCHA and the Google
                                                    <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                                                    <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                                                </p>
                                            </div>



                                            <div id="formErrorInfo" class="formErrorInfoHiden">
                                                {i18next.t('Please correct the form')}
                                            </div>


                                        </form>
                                    </div>
                                </div>
                            </section>







                            <div id="map-container-6" class="map"></div>


















                            <Footer/>
                            <Suwak/>
                        </div>

                    </div>


                </React.Fragment>

                )
    }
}

















